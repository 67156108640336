import type { Dictionary } from '@types'

import getDictionary from '@lib/helpers/cross-env/get-dictionary'

import useLocale from './useLocale'

const useI18n = (): Dictionary => {
  const locale = useLocale()
  return getDictionary(locale)
}

export default useI18n
