import type { BlogPostImage } from '@types'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function getImageSrc(sizes?: Record<string, BlogPostImage>, size = 'FULL'): string | undefined {
  const possibleSizes: string[] = [
    'FULL',
    '1536X1536',
    '2048X2048',
    'LARGE',
    'MEDIUM_LARGE',
    'MEDIUM',
    'POST-THUMBNAIL',
    'THUMBNAIL',
  ]
  if (sizes) {
    if (sizes[size]?.url) {
      return sizes[size]?.url
    }
    for (let i = 0; i < possibleSizes.length; i += 1) {
      if (sizes[possibleSizes[i]]?.url) {
        return sizes[possibleSizes[i]]?.url
      }
    }
  }
  return undefined
}
