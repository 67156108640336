'use client'

import Image from 'next/image'

import SocialLinks from '@components/social-links'

import Logo from '@images/footer/logo.svg'
import MiddleImg from '@images/footer/middle.png'
import BackgroundTablet from '@images/index/hero/background-tablet.png'
import Background from '@images/index/hero/background.png'

import SearchJobsButton from './components/search-jobs-button'

import styles from './footer.module.scss'

import Link from 'next/link'
import useLocale from '@hooks/client/useLocale'

export default function Footer({ backgroundColor = '#fff' }: { backgroundColor?: string }): JSX.Element {
  const bg = {
    '--footer-bg-desktop': `url(${Background.src})`,
    '--footer-bg-tablet': `url(${BackgroundTablet.src})`,
  } as React.CSSProperties
  const country = useLocale()
  return (
    <div className={styles.background} style={{ backgroundColor }}>
      <div className={styles.footer}>
        <div className={styles.container}>
          <div className={styles['image-container']}>
            <Image src={Logo} width={100} height={100} alt="Logo" />
          </div>
          <div className={styles.title}>SonicJobs</div>
          <div className={styles.subtitle}>Your next career move. Sorted.</div>
          <SearchJobsButton />
          <Image src={MiddleImg} height={193} width={331} className={styles['middle-img']} alt="background image" />
          <p>Contact us</p>
          <SocialLinks />
          <p className={styles.outro}>© 2017 - {new Date().getFullYear()} SonicJobs App Ltd. All rights reserved</p>
          <div className={styles.terms}>
            <Link href={`/${country}/terms`}>Terms & Conditions</Link>
            &nbsp;|&nbsp;
            <Link href={`/${country}/privacy`}>Privacy Policy</Link>
            &nbsp;|&nbsp;
            <Link href={`/${country}/ai-agent`}>AI Agent</Link>
            &nbsp;|&nbsp;
            <Link href={`/${country}/jobs`}>Browse Jobs</Link>
          </div>
        </div>
        <div className={styles['background-container']} style={bg}></div>
      </div>
    </div>
  )
}
