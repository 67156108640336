import nookies from 'nookies'

const setCookie: (name: string, value: string, options?: Record<string, unknown>) => void = (
  name,
  value,
  options = {},
) => {
  if (typeof window === 'undefined') {
    return
  }

  if (!Object.hasOwnProperty.call(options, 'expires')) {
    options.expires = new Date('2100-12-17T03:24:00')
  }

  if (!Object.hasOwnProperty.call(options, 'secure')) {
    options.secure = true
  }

  nookies.set(undefined, name, value, options)
}

export default setCookie
