'use client'

import { useSearchStore } from '@stores/search.store'
import { useUserStore } from '@stores/user.store'

import { FormEvent } from 'react'

import type { JobDescription, JobSearch, Location } from '@types'

import { useShallow } from 'zustand/react/shallow'

import Button from '@components/buttons/button'
import { JobTypeAhead, LocationTypeAhead } from '@components/inputs/typeahead'
import useJobSearch from '@hooks/useJobSearch'
import { searchSubmittedEvent } from '@lib/analytics-events'
import { miToKm } from '@lib/helpers/client'

import styles from './form.module.scss'

export default function Form(): JSX.Element {
  const [nextSearch, addParamsToSearch] = useSearchStore(
    useShallow((state) => [state.nextSearch, state.addParamsToSearch]),
  )
  const { submit, errors, setError } = useJobSearch()
  const profile = useUserStore((state) => state.profile)

  const onFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const defaultFilters: Partial<JobSearch> = {
      fullTime: true,
      partTime: true,
      noExperience: false,
      salary: { type: 'PER_ANNUM', min: 0 },
      remote: false,
      createdAfter: '',
      sort: 'FEATURED',
      maxRadius: profile.user?.maxRadius ?? miToKm(25),
    }
    searchSubmittedEvent({ ...nextSearch, ...defaultFilters }, 'Home')
    submit(defaultFilters as Partial<JobSearch>)
  }

  return (
    <form onSubmit={onFormSubmit} autoComplete="false" className={styles.form}>
      <div className={styles['types-container']}>
        <JobTypeAhead
          className={styles.job}
          currentJob={nextSearch.job}
          setJob={(jobDescription: JobDescription): void => {
            addParamsToSearch({ job: jobDescription })
          }}
          showError={errors.job}
          setShowError={(v: boolean): void => setError(v, 'job')}
        />
        <LocationTypeAhead
          className={styles.location}
          currentLocation={nextSearch.location}
          setLocation={(locationDescription: Location): void => {
            addParamsToSearch({ location: locationDescription })
          }}
          showError={errors.location}
          setShowError={(v: boolean): void => setError(v, 'location')}
        />
      </div>
      <Button variant="yellow" size="lg" className={styles['search-button']} data-pw="search-btn">
        Search jobs
      </Button>
    </form>
  )
}
