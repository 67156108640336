'use client'

import { useEffect, useState } from 'react'

import dynamic from 'next/dynamic'
import Image from 'next/image'
// eslint-disable-next-line custom-rules/no-next-link
import Link from 'next/link'

import type { Country } from '@types'

import Button from '@components/buttons/button/button'
import useLocale from '@hooks/client/useLocale'
import useCookiesConsent from '@hooks/useCookiesConsent'
import {
  localeSelectorChangeLocationEvent,
  localeSelectorCloseEvent,
  localeSelectorShowEvent,
} from '@lib/analytics-events'
import { setCookie } from '@lib/helpers/client'

import UKFlag from './uk-flag.png'
import USFlag from './us-flag.png'

import styles from './popup.module.scss'

const LargeModal = dynamic(() => import('../large-modal'))

type LocaleSelectorPopupProps = {
  userCountry: Country
  preferredCountry: string
}

export default function Popup({ userCountry, preferredCountry }: LocaleSelectorPopupProps): JSX.Element {
  const locale = useLocale()
  const [isOpen, setIsOpen] = useState<boolean>(preferredCountry !== locale && userCountry !== locale)
  const [areCookiesAccepted] = useCookiesConsent()

  useEffect(() => {
    localeSelectorShowEvent(locale, userCountry || false)
  }, [locale, userCountry])

  const closeFn = (): void => {
    setIsOpen(false)
    localeSelectorCloseEvent(locale, userCountry || false)
    if (areCookiesAccepted) {
      setCookie('preferred-country', locale)
    }
  }

  const changeLocation = (): void => {
    localeSelectorChangeLocationEvent(locale, userCountry || false)
    setIsOpen(false)
  }

  return (
    <LargeModal isOpen={isOpen} onClose={closeFn}>
      <h2 className={styles.title}>It looks like you are in the {userCountry?.toUpperCase()}</h2>
      <Image
        src={locale === 'us' ? UKFlag : USFlag}
        alt="Locale selector flag"
        height={83}
        width={166}
        className={styles.flag}
      />
      <p className={styles.prompt}>Would you like to go to the SonicJobs {userCountry?.toUpperCase()} website?</p>
      <Link href={`/${locale === 'us' ? 'uk' : 'us'}`} className={styles.cta} onClick={changeLocation}>
        Go to SonicJobs {userCountry?.toUpperCase()}
      </Link>
      <Button variant="simple-text" onClick={closeFn} className={styles['outro-cta']}>
        Continue on SonicJobs {locale.toUpperCase()}
      </Button>
    </LargeModal>
  )
}
