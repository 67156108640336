'use client'

import Button from '@components/buttons/button'
import { footerSearchJobsButtonEvent } from '@lib/analytics-events'

import styles from './search-jobs-button.module.scss'

export default function SearchJobsButton(): JSX.Element {
  return (
    <Button variant="yellow" size="lg" href="/jobs" className={styles.link} onClick={footerSearchJobsButtonEvent}>
      Search jobs
    </Button>
  )
}
