'use client'

import Image from 'next/image'

import { miscSocialMediaLinkClickEvent } from '@lib/analytics-events'
import { basePath } from '@lib/constants'

import styles from './social-links.module.scss'

type SocialLinksProps = {
  theme?: 'light' | 'dark'
}

type SocialLinkProps = {
  href: string
  alt: string
  iconSize?: number
  iconUrl: string
}

const websitesList = [
  { name: 'facebook', href: 'https://www.facebook.com/SONICJOBS/', alt: 'Facebook', iconSize: 15 },
  { name: 'x', href: 'https://twitter.com/sonicjobsapp', alt: 'X' },
  { name: 'instagram', href: 'https://www.instagram.com/sonicjobs', alt: 'Instagram' },
  { name: 'youtube', href: 'https://www.youtube.com/channel/UCbqoQ80jaeoejeb9JIHZb6Q', alt: 'YouTube' },
  { name: 'linkedin', href: 'https://www.linkedin.com/company/sonicjobs/', alt: 'Linkedin' },
]

function SocialLink({ href, alt, iconSize = 47, iconUrl }: SocialLinkProps): JSX.Element {
  return (
    <a
      className={styles.link}
      href={href}
      target="_blank"
      rel="noreferrer"
      onClick={(): void => miscSocialMediaLinkClickEvent(alt)}
    >
      <Image src={iconUrl} alt={alt} width={iconSize} height={iconSize} className={styles.logo} />
    </a>
  )
}

export default function SocialLinks({ theme = 'light' }: SocialLinksProps): JSX.Element {
  return (
    <div className={`${styles['social-media']} ${styles[theme]}`}>
      {websitesList.map((website, i) => (
        <SocialLink
          href={website.href}
          iconSize={website.iconSize}
          key={i}
          iconUrl={`${basePath}/images/social/${website.name}.svg`}
          alt={website.alt}
        />
      ))}
    </div>
  )
}
