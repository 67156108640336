'use client'

import { useCallback, useEffect, useMemo, useState } from 'react'

import dynamic from 'next/dynamic'
import Image, { StaticImageData } from 'next/image'

import { CarouselBreakPoint } from '@types'

import useI18n from '@hooks/client/useI18n'
import useAddEventListener from '@hooks/useAddEventListener'

import info1 from '@images/index/info/info-1.png'
import info2 from '@images/index/info/info-2.png'
import info3 from '@images/index/info/info-3.png'

import styles from './info-carousel.module.scss'

const Glider = dynamic(() => import('react-glider'))

type InfoElement = {
  src: StaticImageData
  text: string
  altText: string
}

export default function Carousel(): JSX.Element {
  const [mobile, setMobile] = useState<boolean>(false)

  const handleResize = useCallback((): void => {
    const width = window.innerWidth
    if (width > 816) {
      setMobile(false)
    } else {
      setMobile(true)
    }
  }, [])

  useEffect(() => {
    handleResize()
  }, [handleResize])

  useAddEventListener('resize', handleResize)

  const responsive: CarouselBreakPoint[] = useMemo(
    () => [
      {
        breakpoint: 1,
        settings: { slidesToShow: 1, slidesToScroll: 'auto' },
      },
      {
        breakpoint: 562,
        settings: { slidesToShow: 2, slidesToScroll: 'auto' },
      },
      {
        breakpoint: 816,
        settings: { slidesToShow: 3, slidesToScroll: 'auto' },
      },
    ],
    [],
  )

  const { jobs } = useI18n()

  const info: InfoElement[] = useMemo(
    () => [
      { src: info1, text: 'Over 1 million people using SonicJobs', altText: '1 million' },
      { src: info2, text: `Over ${jobs} jobs from leading job boards`, altText: 'job boards' },
      { src: info3, text: 'Over 900 5-star reviews on the iOS app store', altText: 'iOS app store' },
    ],
    [jobs],
  )

  return (
    <Glider
      draggable={mobile}
      id="info"
      hasDots={mobile}
      duration={1.5}
      responsive={responsive}
      resizeLock
      scrollLock={!mobile}
    >
      {info.map((el, i) => (
        <div key={i} className={styles.item}>
          <Image src={el.src} alt={el.altText} />
          <p>{el.text}</p>
        </div>
      ))}
    </Glider>
  )
}
