'use client'

import { useEffect, useState } from 'react'

import { Country } from '@types'

import nookies from 'nookies'

import getUserCountry from '@lib/helpers/server/get-user-country'

import Popup from './popup'

export default function LocaleSelectorPopup(): JSX.Element {
  const preferredCountry = nookies.get()['preferred-country'] || ''
  const [userCountry, setUserCountry] = useState<Country | undefined>(undefined)

  useEffect(() => {
    const setCountry = async (): Promise<void> => {
      const country = await getUserCountry()
      setUserCountry(country)
    }
    setCountry()
  }, [])

  if (!userCountry) return <></>

  return <Popup userCountry={userCountry} preferredCountry={preferredCountry} />
}
