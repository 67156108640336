import { useCallback, useState } from 'react'

import { getCookieConsentValue, setCookie } from '@lib/helpers/client'

import useLocale from './client/useLocale'
import useIsomorphicEffect from './useClientSideLayoutEffect'

const useCookiesConsent = (): [boolean, (value: boolean) => void] => {
  const locale = useLocale()
  const [areCookiesAccepted, setAreCookiesAccepted] = useState<boolean>(getCookieConsentValue())

  const setCookiesConsent = useCallback(
    async (value: boolean): Promise<void> => {
      const { updateConfig: updateMixPanelConfig } = await import('@lib/mixpanel')

      window.dispatchEvent(new Event('cookies-update'))
      setCookie('cookies-are-accepted', String(value))
      setCookie('preferred-country', locale)
      setAreCookiesAccepted(value)
      updateMixPanelConfig()
    },
    [locale],
  )

  useIsomorphicEffect(() => {
    if (!areCookiesAccepted) {
      const callback = (): void => {
        if (getCookieConsentValue()) {
          setAreCookiesAccepted(true)
        }
      }
      window.addEventListener('cookies-update', callback)
      return () => {
        window.removeEventListener('cookies-update', callback)
      }
    }
    return () => {}
  }, [areCookiesAccepted])

  return [areCookiesAccepted, setCookiesConsent]
}

export default useCookiesConsent
