const getBackgroundImage = (srcSet = ''): `image-set(${string})` => {
  if (!srcSet) {
    return 'image-set()'
  }
  const imageSet = srcSet
    .split(', ')
    .map((str) => {
      const [url, dpi] = str.split(' ')
      return `url("${url}") ${dpi}`
    })
    .join(', ')
  return `image-set(${imageSet})`
}

export default getBackgroundImage
